.user-login-wrapper {
  color: white;
  position: relative;
  height: 100%;
  .login {
    position: absolute;
    width: fit-content;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    svg {
      display: block;
      margin: 0 auto;
      &.qr {
        margin: 70px auto;
      }
    }
    .login-button {
      display: block;
      margin: 0 auto;
      font-size: 1.2em;
      font-weight: bold;
      padding: 1em 1.5em;
      border: 1px solid white;
      border-radius: 2em;
      background: none;
    }
    .cookie-login-button {
      margin-top: 20px;
      text-align: center;
      width: 100%;
      text-decoration: underline;
    }
  }
}

.qrCodeWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  .caution {
    text-align: center;
    position: absolute;
    bottom: 10px;
    width: 100%;
    color: white;
    transition: 0.3s;
    background-color: rgba(0, 0, 0, 0.4);
    padding: 5px 0;
  }
}
.content-camera-after {
  width: 90%;
  height: fit-content;
  max-height: 90%;
  background-color: none;
  border-radius: 10px;
  overflow: hidden;
  // overflow: hidden;
  // position: relative;
  .label.statusFlg {
    position: absolute;
    right: 20px;
    top: 20px;
  }
}
